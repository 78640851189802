/* Chrome, Safari, Edge, Opera */
#codes-amount::-webkit-outer-spin-button,
#codes-amount::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
#codes-amount[type="number"] {
  appearance: textfield;
}
